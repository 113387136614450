import React, { useState } from 'react'
import { graphql } from 'gatsby'
import Layout from 'layouts/en'
import { Banner, SummaryTitle, ImagePreview, TabSwitcher, Button, PageBottomCard } from 'components/index'
import Seo from 'components/EN/Seo'
import { ButtonGroup } from 'components/Base/Button'
import ProductCard from 'components/Features/ProductCard'
import Evaluation from 'components/Features/Evaluation'
import SubscribeForm from 'components/SubscribeForm'
import Blog from 'components/Product/Blog'
import Community from 'components/Product/Community'
import classnames from 'classnames'
import useIsWindowWidthSmaller from 'hooks/useWindowWidth'
import * as styles from './index.module.less'

const overView = (props) => {
  const { data } = props
  const { featuresOverviewEnYaml } = data
  const { banner, part2, part3, part5, part6, part7, part8 } = featuresOverviewEnYaml

  const [showForm, setShowForm] = useState<boolean>(false)

  const isMb = useIsWindowWidthSmaller()

  const buttons = [
    {
      text: 'Free Demo',
      href: '/api/client/user/router?demo=Retail',
      btnType: 'primary',
    },
  ]

  return (
    <Layout {...props}>
      <Seo
        title="Sensors Product Suites | SensorsData"
        description="Sensors Product Suites, Customer Journey Orchestration, Digital Customer Management, Product System, Product Introduction, Sensors Data"
        keywords="Sensors Data's products are committed to helping enterprises build customer data platforms and realize user experience management based on customer journey, providing solutions for digital customer management."
      />
      <div className={styles.featuresOverviewWrapper}>
        <div className={styles.bannerWrapper}>
          <Banner
            {...banner}
            buttons={buttons}
            h1tag={true}
            className={classnames(styles.banner)}
            bannerTitleClassName={styles.bannerTitle}
            bannerDescClassName={styles.bannerDesc}
            bannerBtnsClass={styles.bannerBtnsClass}
          />
        </div>
        <div className={styles.wWarrper}>
          <SummaryTitle title="Empowering Digital Customer Management from Concept, Methodology to Engines" />
          <div className="flex justify-center md:mt-[60px] mt-[3rem] px-[2rem]">
            <div className="lg:w-[933px]">
              <ImagePreview language="en" imageUrl={part2.img.publicURL ?? part2.img} />
            </div>
          </div>
          <div className="flex justify-center lg:mt-[60px] mt-[3rem]">
            <ButtonGroup>
              <Button href="/api/client/user/router?demo=Retail" target="_blank" btnType="primary">
                Free Demo
              </Button>
              <Button href="/form/parade/" target="_blank" btnType="primary" ghost>
                Contact us
              </Button>
            </ButtonGroup>
          </div>
        </div>
        <div className={styles.bWarrper}>
          <SummaryTitle title="Three Engines for Customer Journey Orchestration" />
          <div className={styles.partContent}>
            <TabSwitcher
              data={part3.data}
              align="flex-start"
              mbShowShadow={false}
              autoWAndH
              longTitle
              itemContenWrapperClass={styles.itemContenWrapperClass}
              tabsClass={isMb ? null : styles.tabsClass}
              tabItemClass={isMb ? null : styles.tabItemClass}
              detailCenter={false}
            />
          </div>
        </div>
        <div className={styles.bWarrper}>
          <SummaryTitle title="Other Application Products" />
          <div className="md:mt-[60px] mt-[1.6rem] overflow-hidden">
            <ProductCard lang="en" list={['scsjpt', 'scfx', 'scznyy']} />
          </div>
        </div>
        <div className={styles.wWarrper} style={{ paddingBottom: '0px' }}>
          <SummaryTitle title="Customer Journey Orchestration Product Suites" />
          <div className="flex justify-center md:mt-[40px] md:px-0 mt-[3rem] px-[2rem]">
            <div className="lg:w-[1295px]">
              <ImagePreview language="en" imageUrl={part5.img.publicURL ?? part5.img} alt="" />
            </div>
          </div>
          <ButtonGroup className={classnames('md:mt-[60px] mt-[3rem] flex justify-center', styles.buttons)}>
            <Button href="/api/client/user/router?demo=Retail" target="_blank" btnType="primary">
              Free Demo
            </Button>
            <Button href="/form/parade/" target="_blank" btnType="primary" ghost>
              Contact us
            </Button>
          </ButtonGroup>
          <div className={styles.part6}>
            {isMb ? null : <SummaryTitle title="Customer Voice" />}
            <div className="lg:mt-[60px]">
              <Evaluation data={part6?.data} />
            </div>
          </div>
        </div>
        <div className={styles.wWarrper}>
          <SummaryTitle title="Related Content" />
          <div className="mt-[2.6rem]">
            <Blog firstData={part7?.firstData} data={part7?.list} />
          </div>
        </div>
        <div className="bg-[#F9FAFC] overflow-hidden">
          <Community data={part8} />
        </div>
        <PageBottomCard
          title="Ready to Get Started？"
          desc="See how Sensors Data can help you make better customer journey"
          leftButtonText="Contact Sales"
          leftButtonHref="/form/parade/"
          descClassName={styles.pageBottomCardDesc}
          type="newBg"
        />
        {showForm && <SubscribeForm handleClose={() => setShowForm(false)} spare_channel="subscription_update" />}
      </div>
    </Layout>
  )
}

export const query = graphql`
  query {
    featuresOverviewEnYaml {
      banner {
        title
        desc
      }
      part2 {
        img {
          publicURL
        }
      }
      part3 {
        data {
          title
          reverse
          showIcon
          sortTitle
          content {
            img {
              publicURL
            }
            alt
            introduction1
            codeDesc
            buttons {
              btnType
              href
              text
              target
              ghost
            }
            introductionList {
              content
            }
          }
        }
      }
      part5 {
        img {
          publicURL
        }
      }
      part6 {
        data {
          tab
          leftImg {
            publicURL
          }
          leftImgMb {
            publicURL
          }
          widget_id
          desc
          qrcodeBtn
          btnText
          btnLink
        }
      }
      part7 {
        firstData {
          buttonText
          desc
          link
          name
          poster
          poster_mb
          arrow_icon
        }
        list {
          buttonText
          desc
          link
          name
          arrow_icon
        }
      }
      part8 {
        buttonText
        desc
        en_title
        link
        title
        bg {
          publicURL
        }
        hover_bg
        arrow_icon
      }
    }
  }
`

export default overView
